/* Responsive css*/

@media (min-width: 1100px) and (max-width:1920px) {
    .main_content_wrapper div.row {
        width: 100%;
    }

}

@media (min-width: 992px) and (max-width:1500px) {
    .product-info {
        gap: 8px 25px;
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
    }

    .signUp_Box {
        padding-right: 1rem;
        padding-left: 1rem;
    }

}

@media (min-width: 821px) and (max-width:991px) {
    .custom-nav {
        gap: 1rem;
    }

    .nav-wrapper-header a {
        margin-right: 20px;
    }
    .home_navigation a.navbar-brand img {
        max-width: 145px;
    }
}

@media only screen and (max-height: 1400px) {


    .input-wrapper>.search_box {
        display: flex;
    }

    .input-wrapper>.search_box>span {
        transform: none;

    }

    .search_box>.fldname {
        margin: 0 !important;
    }

    .swipe-to-delete .js-delete {
        position: absolute !important;
        top: 0px !important;
        right: 0rem !important;
        bottom: 0 !important;
        left: 1.5rem !important;
        z-index: 1 !important;
        margin: 12px 0;
        border-radius: 10px;
        width: 94%;
        height: 70px;
    }
    .custom-edit-input .custom-edit-box{
        position: absolute !important;
        top: 0px !important;
        right: 0rem !important;
        bottom: 0 !important;
        left: 1.5rem !important;
        z-index: 1 !important;
        margin: 12px 0;
        border-radius: 10px;
        width: 94%;
        background: #87C351;
        height: 70px;
    }

}

@media(max-width: 1280px) {
    main.main_content_wrapper {
        padding-inline: 10px;
    }

    .signUp_Box {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

@media(max-width: 1024px) {
    .bg-img {
        height: calc(100vh - 30px);
    }

    .bg-img-search {
        height: calc(100vh - 2px);
    }

    .signUpForm_box {
        padding: 0;
    }

    .headerSec .navbar-brand img {
        max-width: 130px;
    }

    .profileMain_Box {
        padding-block: 70px;
    }

    .createProfile_Box1 {
        width: 100%;
    }

    .createProfile_Sec {
        background-color: #fff;
    }

    .createProfile_Box1.createProfile_Box2 {
        display: none;
    }

    .custom-card {
        margin-block: 10px;
    }

    h1.home_heading {
        font-size: 30px;
    }

    .signUp_Box h1 {
        font-size: 40px;
    }

    .pagination li.active,
    .pagination li:first-of-type,
    .pagination li:last-of-type,
    .pagination li:nth-of-type(1),
    .pagination li:nth-last-of-type(1) {
        position: initial;
        top: initial;
        left: initial;
    }

    .pagination li:nth-of-type(2) a {
        border-left-width: 0;
    }

    .pagination-wrapper .pagination .page-item .page-link {
        padding: 5px 6px !important;
        min-width: 35px !important;
    }
    .cards .col-md {
        flex: auto !important;
    }
    .card-border-radious {
        margin-bottom: 10px;
    }
    span.eye_iconA{
        right: 6% !important;
    }
    .home_navigation a.navbar-brand img {
        max-width: 118px;
    }
    .custom-nav{
        gap: 1rem;
    }
    .nav-wrapper-header a {
        margin-right: 30px;
    }
}

@media screen and (max-width:991px) {
    .registrationsection {
        max-height: none;
        overflow: visible;
    }
}

@media(max-width: 820px) {
    .pagination li {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .pagination li.active,
    .pagination li:first-of-type,
    .pagination li:nth-of-type(2) {
        position: initial;
        top: initial;
        left: initial;
    }

    .pagination li.active,
    .pagination li:first-of-type,
    .pagination li:nth-of-type(3),
    .pagination li:nth-of-type(4),
    .pagination li:nth-of-type(5) {
        position: initial;
        top: initial;
        left: initial;
    }

    .pagination li:nth-of-type(1) a {
        border-left-width: 0;
    }

    .pagination-wrapper {
        margin: 0% auto;
    }
    .cards .col-md {
        flex: auto !important;
    }
    .card-border-radious {
        margin-bottom: 10px;
    }
    span.eye_iconA{
        right: 6% !important;
    }
    .home_navigation a.navbar-brand img {
        max-width: 118px;
    }
    .custom-nav{
        gap: 1rem;
    }
    .nav-wrapper-header a {
        margin-right: 30px;
    }
}

@media (min-width: 768px) {
    [class*="col-"] {
        margin-right: 0 !important;
    }

}

@media(max-width: 768px) {
    .nav-wrapper-header a {
        margin-right: 20px !important;
    }

    .pagination li {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .pagination li.active,
    .pagination li:first-of-type,
    .pagination li:nth-of-type(2) {
        position: initial;
        top: initial;
        left: initial;
    }

    .pagination li.active,
    .pagination li:first-of-type,
    .pagination li:nth-of-type(3),
    .pagination li:nth-of-type(4),
    .pagination li:nth-of-type(5) {
        position: initial;
        top: initial;
        left: initial;
    }

    .pagination li:nth-of-type(1) a {
        border-left-width: 0;
    }

    .pagination-wrapper {
        margin: 0% auto;
    }

    .swipe-to-delete {
        padding-left: 5px !important;
    }
    /* For Dashboard */
    .cards .col-md{
      flex:auto !important;
    }
    .sidebar-sec {
        width: 260px;
    }
    .dashboard-body{
        margin-left: 270px;
    }
    .card-border-radious {
        margin-bottom: 10px;
    }
    span.eye_iconA{
        right: 9% !important;
    }
    .home_navigation a.navbar-brand img {
        max-width: 120px;
    }
}

@media(max-width: 767px) {
    .img-adjust {
        min-height: 350px;
    }
    .product-heading table {
        width: 100% !important;
    }

    .product-heading #eBay-bby-content .lft-flt {
        width: 100% !important;
    }

    .product-heading #eBay-bby-content {
        width: 100% !important;
        min-width: 100% !important;
    }

    .swipe-to-delete {
        padding-left: 5px !important;
        height: 110px !important;
    }

    .swipe-to-delete .js-delete {
        border-radius: 10px !important;
        width: 88% !important;
        height: 60px !important;
    }

    .fsearch_bar-wrapper {
        padding: 20px 10px !important;
    }

    .input-folder {
        margin-bottom: 0 !important;
    }

    .createProfile_FormBox {
        margin-top: 0;
    }

    .custom-card .product-img-wrapper {
        height: 110px;
    }

    .headerSec {
        margin: 6px;
    }

    .fpage_wrapper.comp_page-css {
        max-height: calc(100vh - 255px);
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 5px 8px;
    }

    .option-wrapper.my-3 .btn-group.filter-wrapper {
        justify-content: flex-end;
    }

    .home_navigation button.custom_toggle_btn {
        padding: 5px 10px;
        box-shadow: 0px 1px 6px #8b818166;
    }

    .spinnerrow .option-wrapper.my-3 {
        flex-direction: row;
        justify-content: space-between;
    }

    .signUpForm_BTN button.btn {
        margin: 20px 0;
        padding: 12px;
        font-size: 18px;
    }

    .signUp_Box h1 {
        font-size: 30px;
        max-width: 320px;
        margin-inline: auto;
    }

    .signUp_Box>span {
        font-size: 20px;
        margin-top: 10px;
    }

    .signUpForm_box {
        padding: 0px 20px 20px 20px;
    }

    .createProfile_Sec .signUp_Box h1 {
        text-align: center;
    }

    span.reseller-info {
        padding: 0 10px;
    }

    .fpage_wrapper {
        max-height: calc(100vh - 154px);
        padding: 10px 0 !important;
    }

    .createProfile_Sec .profileMain_Box {
        padding-block: 20px;
        padding-inline: 20px;
    }

    .home_navigation .custom-nav .nav-wrapper-header {
        position: fixed;
        z-index: 999;
        bottom: 0;
        gap: 5px;
        justify-content: space-between;
        left: 0;
        right: 0;
        padding: 15px;
        background-color: #fff;
        box-shadow: 0 6px 35px -12px rgb(0 0 0 / 42%);
    }

    .sidebar {
        display: none;
    }

    main.main_content_wrapper {
        width: auto;
        margin-left: auto;
        padding-inline: 4px;
    }

    .page_wrapper {
        padding: 15px 0 100px 0;
    }

    h1.home_heading {
        font-size: 30px;
        font-weight: 900;
    }

    .search_bar-wrapper {
        padding: 25px 10px;
        box-shadow: none;
        border-radius: 20px;
        display: flex;
        justify-content: space-between;
        max-width: 860px;
        margin: 0px auto;
        flex-wrap: wrap;
        background: transparent;
    }

    .input-wrapper input.form-control:focus {
        border: 1px solid #4ca45a;
        box-shadow: 0px 15px 33px #AFB5B091;
    }

    .input-wrapper input.form-control {
        padding: 12px 15px 12px 40px;
        border: 1px solid #4ca45a;
        border-radius: 10px;
        box-shadow: 0px 15px 33px #AFB5B091;
    }

    .input-folder input.form-control:focus {
        border: none !important;
        box-shadow: none !important;
    }

    .input-folder input.form-control {
        padding: 12px 15px 12px 40px;
        border: none !important;
        border-radius: 0 !important;
        box-shadow: none !important;
    }

    .fldname {
        font-size: 16px;
        padding-left: 10px;
    }

    span.input_search_icon svg {
        width: 30px;
        height: 16px;
    }

    span.input_search_icon {
        left: 10px;
    }

    .screnQrCustm {
        margin-bottom: 22px;
        width: 100%;
        box-shadow: 0px 15px 33px #AFB5B091;
        background: #fff;
        margin-right: 0px !important;

    }

    .input-wrapper {
        position: relative;
        width: 100%;
        margin-bottom: 22px;
    }

    .input-wrapper input.form-control::placeholder {
        font-size: 15px;
    }

    .screnQrCustm input.form-control::placeholder {
        font-size: 15px;
    }

    #appleicon {
        display: block;
        position: relative;
    }

    .tooltipText {
        display: block !important;
        position: absolute;
        top: 35px;
        font-size: 12px;
        left: -17px;
        color: #E91E63;
        transition: all .5s;
        opacity: 0;
    }

    .screnQrCustm .appleicon:hover .tooltipText {
        opacity: 1;
        transform: translateY(-2px);
    }

    .btn-grp_wrapper button.btn.custom_btn {
        width: 100%;
    }

    .btn-grp_wrapper button.btn.custom_btn:first-child {
        margin-top: 30px;
    }

    .btn-grp_wrapper button.btn.custom_btn:last-child {
        margin-top: 10px;
    }

    .btn.action_btn {
        padding-block: 12px;
        margin: 0 auto;
        max-width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .mobile-header-heading {
        display: block;
    }

    .home_heading {
        display: none;
    }

    .search_bar-wrapper .btn-grp_wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    .mobile-nav.text-start {
        display: block;
    }

    .signUp_sec {
        margin-top: 40px;
    }

    .customNav_bar {
        margin-top: 15px;
    }

    .signUp_Box p {
        margin: 20px 0;
    }

    .spinnerrow .d-flex {
        padding-left: 0 !important;
        flex-wrap: wrap !important;
    }

    .spinnerrow .d-sort {
        display: flex;
        gap: 0;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .card-width {
        padding-inline: 4px !important;
        margin-bottom: 0;
    }

    .card_content {
        padding: 6px 8px;
    }

    h3.product-heading.mt-2 small {
        font-size: 16px;
    }

    .product-info {
        margin-top: 10px;
        display: flex;
        gap: 5px;
        font-size: 11px;
        font-weight: bold;
        grid-template-columns: auto auto auto;
        align-items: center;
    }

    .product-info span.popularity small {
        font-size: 12px;
    }

    span.shipping-price svg,
    span.popularity svg {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        vertical-align: text-bottom;
    }

    span.shipping-price {
        font-size: 12px;
        /* margin-left: auto; */
    }

    span.product-cost {
        font-size: 12px;
    }

    span.product-cost svg {
        width: 12px;
    }

    .date-str-info .folder-info {
        position: absolute;
        top: 70px !important;
    }

    .pagination-wrapper {
        margin: 5% auto;
    }

    .custom-carousel .carousel .control-arrow {
        top: 5px !important;
    }

    .custom-carousel .carousel .thumb {
        height: 40px !important;
    }

    .home_navigation a.nav_icon_link {
        text-align: center;
        font-size: 15px;
    }

    .home_navigation a.nav_icon_link span.nav_icon {
        display: block;
        margin: 0;
        margin-bottom: 10px;
    }

    .nav-wrapper-header a.nav_icon_link:last-child {
        display: inline-block;
    }

    .main_content_wrapper div.row {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1 * var(--bs-gutter-y));
        margin-right: calc(-.5 * var(--bs-gutter-x));
        margin-left: calc(-.5 * var(--bs-gutter-x));
    }

    .set-time .date-product {
        top: 20px !important;
        right: 15px !important;
    }

    .set-time .folder-details {
        right: 15px !important;
        bottom: 50px !important;
    }

    .product-info .popularity strong {
        font-size: 20px;
    }
    /* For admin dashboard */
    .sidebar-sec {
        width: 150px;
    }        
    .sidebar-sec .sidebar-menu .dashboard-list li a i {
        margin-right: 12px;
        font-size: 25px;
    }
    .link-name{
        font-size: 15px;
    }
    .dashboard-body {
        width: calc(100% - 140px);
        margin-left: 140px;
    }
    .card-border-radious{
        margin-bottom: 10px;
        min-height: 100px;
    }
    .input-place::placeholder{
        font-size: 11px;
    }
    .main-content h2{
        font-size: 20px;
    }
    span.eye_iconA {
        right: 9% !important;
    }
}


@media(max-width: 567px) {

    .option-wrapper .btn {
        padding: 6px 9px !important;
    }

    .dropdown .btn-toggle {
        padding: 6px 9px !important;
    }

    .home_navigation a.nav_icon_link {
        margin: 0;
        font-size: 15px;
    }

    .tab-wrapper .nav-item .nav-link {
        padding: 15px 7px;
    }

    .product-info {
        grid-gap: 10px;
        gap: 4px 15px;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    h3.product-heading.mt-2 {
        min-height: 10px;
    }

    .pagination-wrapper .pagination .page-item .page-link {
        padding: 5px 6px !important;
        min-width: 35px !important;
    }

    .pagination-wrapper .pagination {
        padding: 5px !important;
    }
}



/* For landscape */
@media screen and (orientation:landscape) and (min-device-width: 360px) and (max-device-width: 900px) {
    .bg-img {
        height: 100%;
    }

    .bg-img-search {
        height: 100%;
    }

    .nav-wrapper-header a {
        margin-right: 20px !important;
    }

    .pagination li {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .pagination li.active,
    .pagination li:first-of-type,
    .pagination li:nth-of-type(2) {
        position: initial;
        top: initial;
        left: initial;
    }

    .pagination li:nth-of-type(2) a {
        border-left-width: 0;
    }

    .pagination li.active,
    .pagination li:first-of-type,
    .pagination li:nth-of-type(3),
    .pagination li:nth-of-type(4),
    .pagination li:nth-of-type(5) {
        position: initial;
        top: initial;
        left: initial;
    }
}


@media screen and (max-width:767px) {
    .customInput_Box input {
        padding: 12px 20px;
    }

    span.eye_icon {
        right: 6%;
        top: 13px;
    }

    span.eye_icon svg {
        width: 18px;
        height: 18px;
    }

    .checkout_Boxcstm1 .checkout_Form_box {
        padding: 30px 30px;
    }

    .main_content_wrapper2 .fldsearch {
        padding: 15px 20px !important
    }

    .main_content_wrapper2 .addfld1 {
        padding: 28px 20px !important;
    }
}

@media screen and (max-width:767px) {
    .main_content_wrapper2 .fldsearch {
        padding: 8px 15px !important
    }

    .main_content_wrapper2 .addfld1 {
        padding: 21px 15px !important;
    }
}

@media only screen and (max-width: 767px) {
    li.nav-item {
        font-size: 14px;
    }

    .option-wrapper.my-3 {
        width: 100%;
        flex-direction: column;
        align-items: baseline;
    }
}

@media(max-width:281px) {
    .product-info span.popularity small {
        font-size: 0.6rem;
    }

    .product-info span.shipping-price svg {
        width: 16px;
    }

    .product-info span.shipping-price {
        font-size: 0.6rem;
    }

    .product-info span.product-cost {
        font-size: 0.7rem;
    }

    .product-info span.product-cost svg {
        width: 16px;
    }

    ul.pagination li.page-item .page-link {
        font-size: 0.7rem;
    }
}

/* For Pagination */
@media only screen and (max-width: 1025px) {
    .pagination li:nth-of-type(2) a {
        border-left-width: 1px;
    }
}

@media only screen and (max-width: 641px) {
    .pagination li {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .pagination li.active,
    .pagination li:first-of-type,
    .pagination li:nth-of-type(2) {
        position: initial;
        top: initial;
        left: initial;
    }

    .pagination li:nth-of-type(2) a {
        border-left-width: 0;
    }
}

@media only screen and (max-width: 481px) {

    h1 {
        font-size: 1.35em !important;
    }

    .pagination li:nth-of-type(2) a {
        border-left-width: 1px;
    }
}

@media only screen and (max-width: 576px) {

    .Coupon_Input {
        max-width:165px
    }
    .Coupon_btn1{
        width:auto;
    }
    ul.pagination {
        flex-wrap: wrap;
        justify-content: center;
    }

    a.custom-card-wrapper span.accordion-header {
        display: inline-block;
        width: 85%;
    }

    a.custom-card-wrapper span.input_search_icon {
        display: flex;
        width: 15%;
        height: 50px;
        padding: 5px;
        justify-content: center;
        align-items: center;
    }

}


@media screen and (max-width:380px){
  
    .input-wrapper {
        margin-bottom: 0px;
    }

 

 
    .checkout_Boxcstm1 .checkout_Form_box {
        padding: 16px;
    }
  
}

@media(max-width: 360px) {
    .tab-wrapper .nav-item .nav-link {
        padding: 12px 10px;
    }
    .signUp_sec {
        margin-top: 25px;
    }

    .headerSec {
        margin: 4px 0;
    }

    h3.product-heading.mt-2 {
        min-height: 8px;
    }

    span.product-cost {
        margin-top: -10px;
    }

    .product-info {
        grid-gap: 0px;
        gap: 14px;
        flex-wrap: wrap;
    }

    .pagination li.active,
    .pagination li:first-of-type,
    .pagination li:nth-of-type(3),
    .pagination li:nth-of-type(4) {
        position: initial;
        top: initial;
        left: initial;
    }

    .pagination li {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .pagination-wrapper .pagination {
        padding: 3px !important;
    }
}

@media screen and (max-width:576px) {
    .dataListingBox ul li {
        width: auto;
    }

    .dataListingBox ul li span {
        font-size: 14px;
    }

    .mobile-nav.text-start a {
        font-size: 15px;
    }
}

@media screen and (min-width:330px) and (max-width:450px) {
    .mobile-nav.text-start a{
        width: 325px;
        display: block;
        position: relative;
        padding-left: 25px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: break-word;
    }

    .mobile-nav.text-start a svg {
        position: absolute;
        top: 2px;
        left: 0;
    }
    .flex-res{
        display: flex !important;
    }
    .mobile-nav.text-start .pro-title{
        width:250px;
    }
}

@media screen and (min-width:280px) and (max-width:485px) {
    .dataListingBox ul li span:nth-child(2)::after {
        width: 30%;
        /* width: 70%; */
    }
}
/*5/1/2024*/
@media screen and (max-width: 992px) {
    #title_product {
      visibility: hidden;
      clear: both;
      float: left;
      margin: 10px auto 5px 20px;
      width: 28%;
      display: none;
    }
    #prodct-title{
        font-size: 22px;
    }
  }