@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Roboto";
  src: url(/static/media/Roboto-Black.b1ab8f33.woff2) format("woff2"),
    url(/static/media/Roboto-Black.17486942.woff) format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(/static/media/Roboto-Bold.ba18ba6b.woff2) format("woff2"),
    url(/static/media/Roboto-Bold.d4b81101.woff) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(/static/media/Roboto-Medium.c19c88ee.woff2) format("woff2"),
    url(/static/media/Roboto-Medium.f22f23c8.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(/static/media/Roboto-Regular.81f751c7.woff2) format("woff2"),
    url(/static/media/Roboto-Regular.c5867568.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/*============================================================
  Reset css start
===============================================================*/
html,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  font-family: "Roboto";
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none !important;
}

/* change colours to suit your needs */
ins {
  color: #fff;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

a,
a:hover {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

img {
  max-width: 100%;
}

/*============================================================
  Reset css end
===============================================================*/

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto";
}

/*============================================================
  Sign Up CSS Section Start
===============================================================*/
.headerSec {
  margin: 30px 0;
}

.signUp_sec {
  margin-top: 60px;
}

.customNav_bar li a {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  padding: 8px 15px !important;
  font-family: "Roboto";
  text-decoration: none;
}

.customNav_bar li a:hover,
.customNav_bar li a.active {
  color: #4ea459 !important;
  text-decoration: none;
}

.custom-toggle .navbar-toggler-icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  vertical-align: middle;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAVCAYAAADM+lfpAAAABHNCSVQICAgIfAhkiAAAAKFJREFUSEtj9FsSeZ6BgcEAiAcW/GeYyOi3JGLB//+MCgPrEqDtjAwbGAfcEUgOGHUMrtgYDZnRkCE1pzL6LomYD9Q08OUMA+NGRt/FERcYGRn1SfUF1dWDSmCqG0qBgaOOGc3apCaf0TSDM834LY2o//+fwYHUIKW6+v+ME4ElcOQDYFzJU91wEg38z/B/IzjN+CyOGNCQ+cfE9GFb9LILACc3H9CaXuziAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-toggler {
  border: none !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

/*============= Sign Up Form =============*/
/* Login background iamge section */

.bg-img {
  height: calc(100vh - 30px);
  background-image: url(/static/media/bg.a222565d.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.bg-img-search {
  height: calc(100vh - 2px);
  background-image: url(/static/media/bg.a222565d.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.signUp_Box {
  width: 100%;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.signUp_Box h1 {
  color: #000;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
}

.signUp_Box p {
  display: block;
  text-align: center;
  font-size: 25px;
  font-weight: 500;
  margin: 30px 0px;
  color: #4ea459;
}

.signUpForm_box {
  padding: 0px 50px;
}

.customInput_Box input {
  padding: 15px 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  color: #000;
  position: relative;
  box-shadow: 0px 8px 10px #c1bdbd42;
  font-family: "Roboto";
}

select {
  padding: 13px !important;
  border: 1px solid #ddd !important;
  border-radius: 10px !important;
  -webkit-appearance: auto !important;
          appearance: auto !important;
}

.customInput_Box input:focus {
  color: #000;
  border-color: #ddd;
  /* box-shadow: unset; */
  box-shadow: rgb(17 18 18 / 20%) 0px 8px 24px;
}

.signUpForm_BTN button.btn {
  display: block;
  width: 100%;
  background: #87c351;
  color: #fff;
  padding: 13px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 500;
  box-shadow: rgb(17 18 18 / 20%) 0px 8px 24px;
}

.customPass a {
  color: #4ea459;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
}

.customPass a:hover {
  color: #068d18;
}

.signUpForm_BTN > span {
  display: block;
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  color: #333333;
  font-family: arial;
}

.signUpForm_BTN > div > a {
  color: #4ea459;
  color: var(--active-color);
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}

.customPass > div > a:hover {
  color: #068d18;
}

.signUpForm_BTN > span > a {
  text-decoration: none;
  color: #4ea459;
  color: var(--active-color);
}

.signUpForm_BTN > span > a:hover {
  color: #068d18;
}

.error {
  color: red;
  font-size: 14px;
  padding-left: 6px;
}

/*============================================================
    Create Profile CSS Section Start
===============================================================*/
.createProfile_Sec {
  background-color: #bad59e;
  height: 100vh;
}

.profileMain_Box {
  display: flex;
}

.createProfile_Box1 {
  width: 50%;
  background-color: #fff;
}

.createProfile_Box2 {
  background: url("/Images/CreateProfile.png") no-repeat;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.createProfile_FormBox > h1,
.createProfile_FormBox > span {
  text-align: start;
  margin-top: 20px;
}

.createProfile_FormBox {
  margin-top: 1.5rem;
}

/*============= CustomFile Input =============*/
.filelabel {
  border: 2px dashed #ddd;
  border-radius: 10px;
  padding: 13px;
  transition: border 300ms ease;
  cursor: pointer;
  text-align: center;
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.csvlabel {
  height: 20rem !important;
}

.filelabel > .uploadImage {
  font-size: 45px;
  color: #ddd;
  margin-bottom: 10px;
}

.filelabel > span {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}

/*============================================================
  Create Profile CSS Section End
===============================================================*/

/*============================================================
    Home CSS Section Start
===============================================================*/

:root {
  --primary-text-color: #333;
  --secondary-text-color: #7e7d7d;
  --active-color: #4ea459;
}

.custom-nav {
  display: flex;
  grid-gap: 4rem;
  gap: 4rem;
  align-items: center;
}

.home_navigation {
  padding-top: 20px;
  padding-bottom: 20px;
}

.home_navigation a.navbar-brand img {
  max-width: 165px;
}

.home_navigation a.nav_icon_link {
  color: #7e7d7d;
  color: var(--secondary-text-color);
  font-weight: 600;
  font-family: "Roboto";
}

.home_navigation .nav_icon {
  vertical-align: 3px;
  display: inline-block;
  margin-right: 6px;
}

.home_navigation .nav_icon svg path {
  width: auto;
  height: 22px;
  stroke: #7e7d7d;
  stroke: var(--secondary-text-color);
}

.home_navigation .nav_icon svg circle {
  fill: #7e7d7d;
  fill: var(--secondary-text-color);
}

.home_navigation a.nav_icon_link.active svg circle {
  fill: #4ea459;
  fill: var(--active-color);
}

.home_navigation a.nav_icon_link.active svg path {
  stroke: #4ea459;
  stroke: var(--active-color);
}

.home_navigation a.nav_icon_link.active svg line {
  stroke: #4ea459;
  stroke: var(--active-color);
}

.home_navigation a.nav_icon_link.active svg rect {
  stroke: #4ea459;
  stroke: var(--active-color);
}

.home_navigation .secondary_nav_wrapper {
  grid-gap: 90px;
  gap: 90px;
}

.home_navigation button.custom_toggle_btn {
  background: #87c351;
  border-radius: 10px;
  border: none;
  padding-block: 10px;
}

.home_navigation button.custom_toggle_btn svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}

.page_wrapper {
  position: relative;
  /* padding: 50px 0;  */
}

.sidebar {
  max-width: 100px;
  min-width: 45px;
  position: absolute;
  left: 0;
  top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

main.main_content_wrapper {
  width: calc(100% - 0px);
  margin-left: auto;
  margin-right: auto;
  /*change*/
}

.sidebar .back_btn {
  border-radius: 8px;
  border: 1px solid #aaa;
  width: 31px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.sidebar .back_btn svg {
  height: 12px;
  fill: #7e7d7d;
  fill: var(--secondary-text-color);
}

h1.home_heading {
  font-size: 50px;
  font-weight: 600;
}

.search_bar-wrapper {
  padding: 25px 30px;
  box-shadow: 0px 15px 33px #afb5b091;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  max-width: 860px;
  margin: 50px auto;
  background: #fff;
}

.input-wrapper {
  position: relative;
}

.input-wrapper input.form-control {
  padding: 12px 15px 12px 40px;
  border: none;
  font-family: "Roboto";
}
.screnQrCustm input.form-control {
  border: none;
  font-family: "Roboto";
}

.input-wrapper input.form-control:focus {
  /* border: none; */
  box-shadow: none;
}

.input-wrapper input.form-control::-webkit-input-placeholder {
  color: #6c757d;
}

.input-wrapper input.form-control::placeholder {
  color: #6c757d;
}

span.input_search_icon {
  position: absolute;
  top: 48%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

span.screnQrCustm svg {
  width: 35px;
  height: 20px;
  fill: #4ea459;
}
span.input_search_icon svg {
  width: 35px;
  height: 20px;
  fill: #4ea459;
}

button.btn.custom_btn {
  border: 1px solid #bbb;
  border-radius: 20px;
  padding: 13px 25px;
}

button.btn.custom_btn svg {
  width: 20px;
  height: 20px;
  fill: #7e7d7d;
  fill: var(--secondary-text-color);
  margin-right: 6px;
}

.btn.action_btn {
  display: block;
  width: 100%;
  max-width: 350px;
  color: #fff;
  padding: 23px 13px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 500;
  margin: 90px auto;
  background: #87c351;
  box-shadow: 0px 12px 19px #00000042;
}

.btn.action_btn:disabled {
  color: white;
  pointer-events: none;
  background: #87c351;
  border: 1px solid #00000042;
  opacity: 1;
}

#appleicon {
  display: none;
}

.home_navigation a.nav_icon_link.active,
.secondary_nav_wrapper a:hover {
  color: #4ea459;
  color: var(--active-color);
}

.sidebar_link_wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  gap: 40px;
  margin-top: 50px;
}

a.nav_icon_link {
  text-decoration: none;
}

.dropdown_wrapper {
  position: relative;
}

.custom_dropdown {
  position: absolute;
  min-width: 300px;
  right: 0;
  top: 0;
  z-index: 9999;
  display: none;
}

.custom_dropdown1 {
  position: absolute;
  min-width: 300px;
  right: 0;
  top: 0;
  z-index: 9999;
  display: block;
}

.close_btn.btn {
  display: block;
  margin-left: auto;
}

.close_btn1.btn {
  display: none;
  margin-left: auto;
}

.custom_dropdown_nav {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 10px 30px rgb(0 0 0 / 5%);
  padding: 30px;
  display: grid;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.custom_dropdown_nav a,
.custom_dropdown_nav a:hover {
  color: #333;
  color: var(--primary-text-color);
  font-weight: 400;
  text-decoration: none;
}

.custom_dropdown_nav a svg {
  width: 20px;
  height: 20px;
  vertical-align: -2px;
  margin-right: 10px;
  fill: #333;
  fill: var(--primary-text-color);
}

.custom_page_dropshadow {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  opacity: 1;
  background-color: #4ea45950;
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  z-index: 999;
  display: none;
}

.custom_page_dropshadow1 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  opacity: 1;
  background-color: #4ea45950;
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  z-index: 999;
  display: block;
}

button.close_btn.btn {
  border: 1px solid #fff;
  border-radius: 15px;
  padding-block: 10px;
  padding-inline: 15px;
  margin-bottom: 10px;
}

button.close_btn.btn svg {
  width: 12px;
  height: 12px;
  fill: #fff;
}

.dropdown_active {
  display: block;
}

.nav-wrapper-header {
  display: flex;
}

.mobile-header-heading {
  font-size: 18px;
  display: none;
}

.nav-wrapper-header a.nav_icon_link:last-child {
  display: block;
}

.tab-wrapper .nav-item .nav-link {
  color: #7e7d7d;
  color: var(--secondary-text-color);
  font-weight: 700;
  padding: 15px 3px;
  border-bottom: 2px solid transparent;
  border-radius: 0;
}

.tab-wrapper :is(.nav-pills .nav-link.active, .nav-pills .show > .nav-link) {
  background-color: transparent;
  color: #4ea459;
  color: var(--active-color);
  border-bottom: 2px solid #4ea459;
  border-bottom: 2px solid var(--active-color);
}

.tab-wrapper ul#pills-tab {
  border-bottom: 1px solid #99eca4;
}

.custom-card-wrapper {
  color: #333;
  color: var(--primary-text-color);
}

.custom-card-wrapper:hover {
  color: #333;
  color: var(--primary-text-color);
}

.custom-card {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.custom-card .product-img-wrapper {
  position: relative;
  height: 170px;
}

.custom-card .product-img-wrapper::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: #000;
  opacity: 0.15;
}

.custom-card .product-img-wrapper img {
  aspect-ratio: 16/9;
  /* object-fit: cover; */
  object-fit: contain;
  height: 100% !important;
}

.card-width {
  margin-bottom: 1.5rem;
}

.product-img-wrapper {
  position: relative;
}

.product-img-wrapper .date-str-info {
  position: absolute;
  top: 10px;
  right: 10px;
  text-align: right;
  font-size: 13px;
  font-weight: bold;
}

.product-img-wrapper .date-str-info > span {
  display: inline-block;
}

.product-img-wrapper .date-str-info > span:last-child {
  display: block;
}

.card_content {
  position: relative;
  padding: 15px;
  font-family: "Roboto";
}

h3.product-heading.mt-2 {
  font-size: 20px;
  line-height: 1.2;
  min-height: 48px;
}

h3.product-heading.mt-2 small {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 18px;
}

.product-info {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  font-weight: bold;
  grid-template-columns: auto auto auto;
  align-items: center;
}

span.product-cost {
  font-size: 18px;
  color: #4ea459;
  color: var(--active-color);
}

span.shipping-price svg,
span.popularity svg {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: text-bottom;
}

.product-info span.popularity small {
  font-size: 15px;
}

span.product-cost svg {
  width: 20px;
  fill: #4ea459;
  fill: var(--active-color);
  margin-right: 3px;
}

span.date-product {
  padding: 5px 6px;
  background: #fff;
  border-radius: 6px;
  color: #4ea459;
  color: var(--active-color);
}

span.date-product svg {
  width: 14px;
  height: 14px;
  fill: #4ea459;
  fill: var(--active-color);
  vertical-align: -2px;
  padding-right: 3px;
}

span.str-product {
  color: #fff;
  margin-top: 8px;
}

.option-wrapper {
  display: flex;
  justify-content: space-between;
  color: #7e7d7d;
  color: var(--secondary-text-color);
}

.option-wrapper .btn,
.option-wrapper .slider {
  color: #505058;
  font-weight: 500;
  font-size: 14px;
  margin: 5px;
  font-family: "Roboto";
  box-shadow: 0px 0px 14px #cccccc30;
  border: 1px solid #4ea4598c;
  border-radius: 10px;
  padding: 6px 15px !important;
  /*change*/
}

.option-wrapper .btn:hover,
.option-wrapper .btn:focus {
  border-color: transparent;
}

.option-wrapper .btn:hover {
  background: #4ea459;
  background: var(--active-color);
  color: #fff;
}

.option-wrapper .btn:hover svg path {
  fill: #fff;
}

.sort-btn span {
  padding-right: 5px;
}

.sort-btn svg {
  width: 11px;
  height: 11px;
  fill: #7e7d7d;
  fill: var(--secondary-text-color);
  vertical-align: -1px;
}

.d-sort {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.filter-wrapper .dropdown-toggle::after {
  display: none;
}

.option-wrapper .dropdown-toggle::after {
  display: none;
}

.main_content_wrapper .profile-btn:hover {
  background: #4ea459;
  background: var(--active-color);
  color: #fff;
}

.filter-wrapper svg {
  width: 14px;
  height: 14px;
  fill: #7e7d7d;
  fill: var(--secondary-text-color);
  vertical-align: -2px;
}

.dropdown .btn-toggle {
  color: #505058;
  font-weight: 500;
  font-size: 14px;
  margin: 5px;
  font-family: "Roboto";
  box-shadow: 0px 0px 14px #cccccc30;
  border: 1px solid #4ea4598c;
  border-radius: 10px;
  padding: 6px 15px !important;
}

.active-listing .product-img-wrapper .date-str-info > span:last-child {
  display: none;
}

span.date-product.red {
  color: #ff000095;
}

span.date-product.red svg {
  fill: #ff000095;
}

.product-details {
  margin-top: 15px;
}

.mobile-nav.text-start {
  display: none;
}

.mobile-nav.text-start a {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  color: var(--primary-text-color);
}

.mobile-nav.text-start a svg {
  width: 16px;
  height: 16px;
  vertical-align: -2px;
  margin-right: 15px;
}

.asterisk_admin::after {
  content: " *";
  color: #f00808;
  position: relative;
  right: -2px;
  float: right;
}

main.main_content_wrapper.main_content_wrapper2
  .js-content
  .custom-card
  .product-details
  .product-info
  span.shipping-price {
  font-size: 0.8rem;
}

main.main_content_wrapper.main_content_wrapper2
  .js-content
  .custom-card
  .product-details
  .product-info
  span.product-cost {
  font-size: 1rem;
}

.pagination-wrapper {
  display: flex;
  margin: 5% auto;
  justify-content: center;
}
#pagination-top {
  margin: 2% auto;
}

.pagination-wrapper .pagination {
  border: 1px solid #4ea459;
  padding: 5px 10px;
  border-radius: 10px;
  box-shadow: 0px 5px 33px #4ea45930;
  background: #fff;
}

.pagination-wrapper .pagination .page-item .page-link {
  border: none;
  color: #000;
  font-weight: 500;
}

.pagination-wrapper .pagination .page-item .page-link:hover {
  background: #4ea459;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
}

.pagination-wrapper .pagination .page-item:first-of-type .page-link {
  border-left-width: 1px;
  padding-left: 20px;
  color: #4ea459;
}

.pagination-wrapper .pagination .page-item:first-of-type .page-link:hover {
  background-color: transparent;
  color: #4ea459;
  border-radius: 0%;
}

.pagination-wrapper .pagination .page-item:last-of-type .page-link {
  border-right-width: 1px;
  padding-right: 20px;
  color: #4ea459;
}

.pagination-wrapper .pagination .page-item:last-of-type .page-link:hover {
  background-color: transparent;
  color: #4ea459;
  border-radius: 0%;
}

span.shipping-price {
  white-space: nowrap;
}

span.product-cost {
  white-space: nowrap;
}

/*loader css*/

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.loading-spinner {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #78e25d;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  position: fixed;
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  z-index: 999;
  display: flex;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.product-detailscard {
  padding: 0px;
}

.product-detailscard .productimg {
  border-radius: 15px 15px 0 0;
  height: 80vh;
}

.product-detailsbody {
  padding: 20px 10px;
}

.product-detailsbody .date-product {
  background-color: #f0f0f0;
  color: #4ea459;
}

.product-detailsbody .date-product svg {
  fill: currentColor;
  stroke: currentColor;
}

span.Productnumbr {
  color: #999;
  font-size: 0.875rem;
  font-weight: 800;
}

button.btnshare {
  display: block;
  width: 100%;
  background: #87c351;
  color: #fff;
  border: 1px solid #87c351;
  box-shadow: none;
  padding: 13px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 500;
  box-shadow: 0px 5px 33px #4ea45933;
}

button.btnshare:hover {
  color: #4ea459;
  border: 1px solid #4ea459;
  background: #fff;
  fill: #4ea459;
  fill: var(--active-color);
}

button.btnshare:hover svg path {
  fill: #4ea459;
  fill: var(--active-color);
}

button.btnshare svg {
  fill: currentColor;
  width: 20px;
  margin-left: 10px;
}

/* Range filter css */
.slidervDiv {
  width: 50%;
  padding-left: 1em;
}

.slidervDiv label:nth-child(1) {
  position: absolute;
  margin-top: 1.3em;
  margin-left: -1em;
}

.slidervDiv input:nth-child(3) {
  position: relative;
  top: -2em;
}

.slidervDiv label:nth-child(4) {
  position: absolute;
  margin-top: -0.7em;
  margin-left: -1em;
}

input[type="range"] {
  margin: 1em;
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  width: 80%;
  margin: 0;
  padding: 3px 0px;
  /* Add some L/R padding to ensure box shadow of handle is shown */
  overflow: hidden;
  border: 0;
  border-radius: 1px;
  outline: none;
  background: linear-gradient(grey, grey) no-repeat center;
  /* Use a linear gradient to generate only the 2px height background */
  background-size: 100% 2px;
  pointer-events: none;
}

input[type="range"]:active,
input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  height: 1em;
  width: 1em;
  border-radius: 1em;
  background-color: #fff;
  position: relative;
  margin: 5px 0;
  /* Add some margin to ensure box shadow is shown */
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  pointer-events: all;
  box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.b::-webkit-slider-thumb {
  background-color: steelblue !important;
}

input[type="range"]::-webkit-slider-thumb::before {
  content: " ";
  display: block;
  position: absolute;
  top: 13px;
  left: 100%;
  width: 2000px;
  height: 2px;
}

.multi-range {
  position: relative;
  height: 50px;
}

.multi-range input[type="range"] {
  position: absolute;
}

.multi-range input[type="range"]:nth-child(1)::-webkit-slider-thumb::before {
  background-color: red;
}

.multi-range input[type="range"]:nth-child(2) {
  background: none;
}

.multi-range input[type="range"]:nth-child(2)::-webkit-slider-thumb::before {
  background-color: grey;
}

/* Range filter css ended */

/* css for folder start*/
.fsearch_bar-wrapper {
  padding: 25px 30px;
  box-shadow: 0px 10px 30px rgb(0 0 0 / 9%);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  max-width: 860px;
  margin: 3px auto;
  flex-wrap: wrap;
  background: white;
}

.inputsearch_bar-wrapper {
  padding: 15px 40px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  max-width: 860px;
  margin: 10px auto;
  flex-wrap: wrap;
  background: white;
  box-shadow: 0px 15px 33px #afb5b091;
}

main.main_content_wrapper.main_content_wrapper2 {
  padding-bottom: 85px;
}

.nav-wrapper-header a:last-child {
  margin: 0;
}

.nav-wrapper-header a {
  margin-right: 90px;
}

.fpage_wrapper {
  position: relative;
  padding: 50px 0;
}

.fldname {
  margin-bottom: 0px !important;
  margin-left: 40px;
}

.input-wrapper input.fldsearch {
  padding: 0px;
  border: none;
}

.addfld {
  color: #4ea459;
}

.addfld1 {
  padding: 28px 45px !important;
}

/* css for folder end*/

/* swipe to delete functionality css */

.swipe-to-delete {
  position: relative !important;
  overflow-x: hidden;
  padding: 0;
  height: 130px;
  padding-left: 20px;
}

.swipe-to-delete .js-delete {
  position: absolute !important;
  top: 0px !important;
  right: 7rem !important;
  /* changes by ch*/
  bottom: 0 !important;
  left: 7rem !important;
  z-index: 1 !important;
  margin: 10px auto;
  border-radius: 30px;
}

.swipe-to-delete .js-content {
  position: relative !important;
  z-index: 2 !important;
}

.swipe-to-delete .js-content :first-child {
  position: relative !important;
}

.swipe-to-delete .js-transition-delete-right,
.swipe-to-delete .js-transition-delete-left,
.swipe-to-delete .js-transition-cancel {
  transition-property: left !important;
  transition-duration: 0.5s;
}

.swipe-to-delete .js-transition-delete-right {
  left: 100% !important;
}

.swipe-to-delete .js-transition-delete-left {
  left: -100% !important;
}

.swipe-to-delete .js-transition-cancel {
  left: 0 !important;
}

.swipe-to-delete .js-delete {
  background: #ff5f58;
}

.swipe-to-delete .js-delete svg {
  position: absolute;
  left: 17px;
  top: 50%;
  margin-top: -13px;
  width: 25px;
  height: 25px;
}

.swipe-to-delete .js-delete svg:first-child {
  left: auto;
  right: 17px;
}

.swipe-to-delete .js-content :first-child {
  cursor: pointer;
}

.swipe-to-delete .js-transition-delete-right,
.swipe-to-delete .js-transition-delete-left,
.swipe-to-delete .js-transition-cancel {
  transition-duration: 0.7s;
  transition-timing-function: ease-in-out;
}

/* swipe to delete ended */

/* ---------------------------css for checkout page------------------------ */
.checkout_BTN button.btn {
  display: block;
  width: 100%;
  background-image: linear-gradient(
    to left,
    #86c251,
    #7bbc53,
    #70b755,
    #66b157,
    #5cab58
  );
  color: #fff;
  padding: 13px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 500;
}

.Coupon_btn {
  width: 15%;
  background-image: linear-gradient(
    to left,
    #86c251,
    #7bbc53,
    #70b755,
    #66b157,
    #5cab58
  );
  color: #fff;
  padding: 2px;
  border-radius: 10px;
}

.Coupon_btn1 {
  width: 15%;
  background-image: linear-gradient(
    to left,
    red,
    red,
    #d31717,
    #c51f2d,
    #e02c2c
  );
  color: #fff;
  padding: 2px;
  border-radius: 10px;
}

/* .coupon-section {
  margin-top: 20px;
} */

.Coupon_Input {
  margin-left: 30px;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.checkout_span {
  color: #66b157;
  /* font-family: cursive; */
  font-size: 19px;
}

/* .checkout_span1 {
  font-family: cursive;

} */

.checkout_span2 {
  margin-left: 45px;
}

.check_customInput_Box {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.checkout_Box {
  width: 100%;
  box-shadow: 0px 5px 30px rgb(0 0 0 / 10%);
  margin: 30px auto;
  border-radius: 30px;
}

.checkout_cta {
  display: flex;
  justify-content: center;
}

.trial_section {
  padding-top: 20px;
}

.sub_details {
  padding-left: 30px;
}

.terms_note {
  font-size: 0.8rem;
  margin-top: -1px;
  margin-bottom: 0 !important;
  padding-left: 30px;
}

/*========stripe css=========================================*/

/* .StripeCheckout span {
  background-image: none !important;
  background: none !important;
} */

button.StripeCheckout {
  background: linear-gradient(
    to left,
    #86c251,
    #7bbc53,
    #70b755,
    #66b157,
    #5cab58
  );
  cursor: pointer;
}

button.DisabledStripeCheckout {
  background: rgba(0, 0, 0, 0.2) !important;
  cursor: default !important;
}

.StripeCheckout {
  margin-left: 0;
  text-align: center;
  overflow: hidden;
  /* display: inline-block; */
  border: 0;
  padding: 1;
  text-decoration: none;
  border-radius: 5px;
  /* box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2); */
  visibility: visible;
  -webkit-user-select: none;
          user-select: none;
}

.StripeCheckout span {
  /* padding-top: 3px !important;
  padding-bottom: 2rem !important;
  padding-left: 5rem !important;
  padding-right: 5rem !important; */
  padding: 0.5rem 5rem;
  position: relative;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 30px;
  color: #000 !important;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  border-radius: 4px;
  display: block;
  height: 30%;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

/*========stripe css=========================================*/
span.checkout_search_icon svg {
  width: 35px;
  height: 35px;
  fill: #66b157;
}

span.checkout_search_icon {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

span.checkout_main_icon svg {
  width: 100px;
  height: 100px;
  margin-left: 13rem;
  fill: #66b157;
  /*color change */
}

.checkout_main_icon {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}

.checkout_Form_box {
  padding-left: 89px;
}

/*------------------------- css end checkout page------------------------------------------------------ */

.active > .page-link,
.page-link.active {
  background-color: #66b157 !important;
  border-color: #66b157 !important;
  border-radius: 50%;
  color: #fff !important;
}

/*create-profile-selectinput--*/
.css-13cymwt-control {
  border: 0px !important;
}

.custom-close {
  border: 1px solid green;
  padding: 0px 8px;
  border-radius: 5px;
  background: #fff;
}

span.pass_icon {
  position: absolute;
  right: 20px;
  /* top: 16px; */
}

span.pass_icon svg {
  width: 18px;
  height: 18px;
  fill: #4ea459;
}

.customInput_Box {
  position: relative;
}

span.eye_icon {
  position: absolute;
  right: 20px;
  top: 16px;
}

span.eye_icon_style {
  margin-top: -2rem !important;
  margin-right: 1rem !important;
}

span.eye_iconA {
  margin-top: -2rem !important;
  position: absolute !important;
  right: 5% !important;
}

span.eye_icon svg {
  width: 20px;
  height: 20px;
  fill: #4ea459;
}

input::-ms-reveal {
  display: none;
}

/* 29-03-2023 */
.registrationsection {
  max-height: 100vh;
  overflow: auto;
}

.cstmscroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.cstmscroll::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.cstmscroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4da358;
}

.multiselectcstm > div,
.multiselectcstm > div:hover,
.multiselectcstm > div:focus {
  border: 0 !important;
  box-shadow: none;
}

.multiselectcstm > div + div {
  z-index: 10;
  left: -0px;
}

.form-control .css-1fdsijx-ValueContainer {
  padding-left: 0;
}

.form-control .css-1u9des2-indicatorSeparator {
  display: none;
}

.form-control .css-1xc3v61-indicatorContainer,
.form-control .css-15lsz6c-indicatorContainer {
  padding: 0;
  margin-right: -10px;
  color: #000;
}

.form-control .css-1xc3v61-indicatorContainer svg,
.form-control .css-15lsz6c-indicatorContainer svg {
  width: 15px;
}

.customInput_Box select {
  padding: 15px 13px !important;
  border: 1px solid #ddd;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 8px 10px #c1bdbd42;
  position: relative;
}

.customInput_Box .form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0px 2px 8px #00000042;
}

.custom-select .form-control {
  border: 1px solid #ddd;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 8px 10px #c1bdbd42;
}

.checkout_Boxcstm1 {
  width: 500px;
  max-width: 100%;
  margin: 30px auto;
}

.checkout_Boxcstm1 .checkout_Form_box {
  padding: 20px 50px;
}

main.main_content_wrapper2 {
  width: 100%;
}

.main_content_wrapper2 .input-wrapper {
  width: 100%;
}

.main_content_wrapper2 .fsearch_bar-wrapper,
.main_content_wrapper2 .inputsearch_bar-wrapper {
  max-width: 100%;
}

.spinnerrow .spinner-container {
  position: fixed;
  left: 0;
}

.spinnerrow .loading-spinner {
  position: absolute;
  right: 0;
  top: 20%;
}

.fsearch_bar-wrapper .input-wrapper .search_box {
  color: black;
  align-items: center;
}

.reBtn > button.btn {
  margin: 20px 0px !important;
}

div span.instruct {
  color: grey !important;
  font-size: 17px !important;
}

.reseller-info {
  text-align: center;
  margin-top: 10px;
  font-family: "Roboto";
}

.reseller-info a {
  color: #4ea459;
  font-weight: 500;
}

.reseller-info a:hover {
  color: #068d18;
}

button.my-btn {
  text-align: left;
  border: none;
  background: none;
}

.no-caret::after {
  display: none !important;
}

.my-btn.btn {
  padding: 0;
}

.tooltip .tooltip-inner {
  background-color: #ffffff;
  color: #000000;
}

.tooltip .arrow::before {
  border-top-color: #ffffff;
  /* display: none; */
}

.folder-info {
  position: absolute;
  top: 90px;
  right: -1px;
}

span.input_search_icon_refresh {
  right: 20px !important;
  left: unset !important;
}

@-moz-document url-prefix() {
  .custom-card .product-img-wrapper img {
    height: 290px;
    width: 100%;
  }

  h3.product-heading.mt-2 small {
    overflow: hidden;
    position: relative;
    height: 42px;
  }

  h3.product-heading.mt-2 small:before {
    background: #ffffff;
    bottom: 0;
    position: absolute;
    right: 0;
    float: right;
    content: "\2026";
    margin-left: -2rem;
    width: 2rem;
  }

  h3.product-heading.mt-2 small:after {
    content: "";
    background: #ffffff;
    position: absolute;
    height: 50px;
    width: 100%;
    z-index: 1;
  }
}

/* Dashboard */
.order-card {
  color: #fff;
}

.bg-c-blue {
  background: linear-gradient(45deg, #4099ff, #73b4ff);
}

.bg-c-green {
  background: linear-gradient(45deg, #2ed8b6, #59e0c5);
}

.bg-c-yellow {
  background: linear-gradient(45deg, #ffb64d, #ffcb80);
}

.bg-c-pink {
  background: linear-gradient(45deg, #ff5370, #ff869a);
}

.card .card-block {
  padding: 15px;
}

.order-card i {
  font-size: 26px;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

/*---dashboard---*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "poppins", sans-serif;
}

:root {
  --black-color: #11101d;
}

ul {
  list-style: none;
}

/* =======================LOGIN=====06=JUNE================================ */
.main-section {
  height: 200px;
  background-size: cover;
  min-height: 100vh;
  background-position: center;
}

.main-section:after {
  content: "";
  display: block;
  width: 100%;
  height: 100vh;
  background: #000000c9;
}

.form-container {
  width: 100%;
  max-width: 600px;
  padding: 50px;
  background: #fff;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  box-shadow: 0px 5px 50px #0000001a;
}

.form-head {
  margin-bottom: 40px;
}

.form-head h2 {
  font-weight: 600;
}

.form-head h2:after {
  content: "";
  display: block;
  width: 40px;
  height: 3px;
  background: #ffdd0e;
  margin-top: 10px;
}

.common-form input {
  min-height: 50px;
}

.forget-pass-link {
  text-align: right;
}

.forget-pass-link > a {
  text-decoration: none;
}

.btn.login-btn {
  background: #ffdd0e;
  width: 100%;
  padding: 10px;
  font-weight: 500;
}

.btn.login-btn:hover {
  background: #e9cb18;
}

.signUp-link {
  text-align: center;
  margin-top: 30px;
}

.signUp-link span {
  font-size: 14px;
}

.signUp-link span > a {
  text-decoration: none;
  color: #d3b505;
  font-weight: 500;
  font-size: 14px;
}

.form-container .form-control:focus {
  border-color: #ffdd0e;
  box-shadow: none;
}

.signUp_Box h6 {
  text-align: center;
  margin-top: -20px;
  padding-bottom: 10px;
}

/* ================Dashboard================= */
.dashboard-section {
  padding: 15px;
  display: flex;
  position: relative;
  isolation: isolate;
}

.sidebar-sec {
  width: 290px;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.5s;
  background: #fafafa;
  box-shadow: 0px 5px 10px #0000004a;
  height: 100%;
}

aside.sidebar-sec.active-sidebar {
  left: -300px;
  transition: all 0.5s;
}

.sidebar-sec .logo-box {
  display: flex;
  align-items: center;
  padding: 20px 10px;
  background: #fff;
  border-bottom: 2px solid #4da456;
}

.sidebar-sec .sidebar-menu {
  border-radius: 20px;
  min-height: calc(100vh - 190px);
}

.sidebar-sec .sidebar-menu .dashboard-list {
  list-style: none;
  padding-left: 0;
  overflow-y: auto;
  max-height: 674px;
}

.sidebar-sec .sidebar-menu .dashboard-list li a {
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  margin-bottom: 10px;
  justify-content: space-between;
  transition: all 0.4s;
}

.sidebar-sec .sidebar-menu .dashboard-list li a.active,
.sidebar-menu .dashboard-list li a:hover {
  background: #a7d37a;
  color: #000;
}

.sidebar-sec .sidebar-menu .dashboard-list li a i {
  margin-right: 14px;
  font-size: 32px;
}

.sub-menu {
  display: none;
  padding-left: 55px !important;
}

.header-sec {
  background: #fff;
  border-radius: 10px;
  padding-right: 10px;
  width: 100%;
  top: 0;
  left: 328px;
}

button.btn.collapse-btn {
  font-size: 50px;
  padding: 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.user-profile {
  display: flex;
  align-items: center;
}

.admin-profile {
  box-shadow: 0px 5px 10px #0000004a;
  padding: 10px;
  border-radius: 10px;
}

.user-profile .user-img {
  max-width: 60px;
  max-height: 60px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 10px;
}

.user-profile .dropdown-toggle {
  background: none;
  border: none;
  color: #000;
}

.user-profile .dropdown .dropdown-menu[data-bs-popper] {
  top: 100%;
  left: -55px;
}

li.listactive ul.sub-menu {
  display: block;
}

ul.inner-sub-menu {
  display: none;
}

.listulactive ul.inner-sub-menu {
  display: block;
}

.dashboard-body {
  width: calc(100% - 300px);
  padding: 0 0 0 15px;
  margin-left: 290px;
  transition: all 0.5s;
}

.dashboard-body.active-body {
  width: 100%;
  margin-left: 0;
  padding-left: 0;
  transition: all 0.5s;
}

.csv {
  padding-top: 50px !important;
}

.page-head-sec {
  padding: 15px 10px;
}

.page-head-sec > h5 {
  color: #000;
  font-weight: 400;
  margin-bottom: 20px;
}

.page-head-sec > h3 {
  color: #000;
}

.data-container {
  background: #fff;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0px 5px 10px #0000004a;
}

.select-menu .form-select {
  max-width: 250px;
}

.table-wrapper {
  margin-top: 20px;
}

.table-wrapper #example_filter {
  text-align: end;
}

.table-wrapper #example_filter input {
  width: 250px;
  height: 45px;
}

.data-head-sec .select-menu .form-select {
  height: 45px;
}

.customer-table thead {
  background: white;
  color: #000;
}

.customer-table thead tr th {
  font-weight: 700;
}

.customer-table th {
  height: 40px;
}

.customer-table th,
td {
  vertical-align: middle;
}

.action-btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.customer-table .action-btn button {
  background: none;
  border: none;
  font-size: 21px;
}

.customer-table .edit-btn {
  color: #0d6efd;
}

.customer-table .delete-btn {
  color: #e92121;
}

.status .form-select {
  height: 40px !important;
}

.card-1 {
  background-color: #62abff;
}

.card-2 {
  background-color: #49ddbf;
}

.card-3 {
  background-color: #ffc570;
}

.card-4 {
  background-color: #ff758c;
}

.card-5 {
  background-color: #79ba00;
}

.heading-sales {
  padding: 0 20px;
}

#customTable_wrapper .dataTables_filter {
  text-align: end;
}

.card-border-radious {
  border-radius: 10px;
  padding: 15px;
  min-height: 126px;
  box-shadow: 0px 5px 10px #0000004a;
}

.comp-card .card {
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0px 10px 30px rgb(0 0 0 / 9%);
  border: none;
}

.comp-card .card-body {
  padding: 20px 30px !important;
}

.pagination li.page-item a {
  padding: 5px 10px !important;
  cursor: default;
  min-width: 37px;
  text-align: center;
}

ul.pagination:first-child a {
  width: 100%;
}

.pagination .page-item:not(:first-child) .page-link {
  margin-left: 0px;
}

.page-next svg {
  margin-left: 15px;
}

.page-pre svg {
  margin-right: 15px;
}

.admin-container {
  background: #fff;
  padding: 30px;
  box-shadow: 0px 5px 10px #0000004a;
  border-radius: 20px;
}

button.btnshare > a {
  color: #fff;
}

/* toggle css strats here */

div.toggle > input[type="checkbox"] {
  position: relative;
  width: 60px;
  height: 30px;
  -webkit-appearance: none;
  appearance: none;
  background: red;
  outline: none;
  border-radius: 2rem;
  cursor: pointer;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 50%);
}

div.toggle > input[type="checkbox"]::before {
  content: "";
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s;
}

div.toggle > input[type="checkbox"]:checked::before {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  background: #fff;
}

div.toggle > input[type="checkbox"]:checked {
  background: #8bc44f;
}

.card.serviceCard {
  border-radius: 20px;
  border: none;
  box-shadow: 2px 2px 15px 2px #e9e9e9;
  padding: 20px 10px;
}

.services > div.row {
  grid-gap: 20px;
  gap: 20px;
}

.serviceLabel > p:last-child {
  color: #9ca4ab;
}

.screnQrCustm {
  display: flex;
  align-items: center;
  border: 1px solid #4ca45a;
  padding: 6px;
  margin-right: 10px;
  border-radius: 10px;
}

.screnQrCustm svg {
  width: 25px;
  height: 25px;
  margin-right: 12px;
  cursor: pointer;
}

.screnQrCustm .form-control:focus {
  border: transparent;
  box-shadow: none;
}

.screnQrCustm input::-webkit-outer-spin-button,
.screnQrCustm input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Carousel CSS */
.main_content_wrapper .back-btn {
  display: flex;
  align-items: center;
  padding-left: 0;
  font-size: 20px;
}

.back-btn:hover {
  color: #4ea459 !important;
}

.back-btn:hover svg path {
  fill: #4ea459 !important;
}

.main_content_wrapper .btn:first-child:active {
  color: #4ea459;
  background-color: #fff;
  border-color: #4ea459;
}

.main_content_wrapper .back-btn svg {
  height: 15px;
  width: 25px;
}

.custom-carousel .carousel.carousel-slider .control-arrow {
  top: 45%;
  color: #fff;
  font-size: 26px;
  bottom: 45%;
  margin-top: 0;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #4ea459;
  box-shadow: 0px 5px 33px #4ea45930;
  position: unset !important;
}

.custom-carousel .carousel.carousel-slider {
  display: flex;
  align-items: center;
}

.custom-carousel .carousel .control-prev.control-arrow {
  left: 0;
}

.custom-carousel .carousel .control-arrow {
  transition: all 0.25s ease-in;
  opacity: 1 !important;
  filter: alpha(opacity=40);
  z-index: 2;
  top: 16px;
  background: none;
  border: 0;
  font-size: 32px;
  cursor: pointer;
}

.custom-carousel .carousel .control-next.control-arrow:before {
  border-left: 8px solid #4ea459;
}

.custom-carousel .carousel .control-next.control-arrow:hover::before {
  border-left: 8px solid #fff;
}

.custom-carousel .carousel.carousel-slider .control-arrow:hover {
  background: #4ea459;
}

.custom-carousel .carousel .control-prev.control-arrow:before {
  border-right: 8px solid #4ea459;
}

.custom-carousel .carousel .control-prev.control-arrow:hover::before {
  border-right: 8px solid #fff;
}

.custom-carousel .carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin: 0 5px;
  display: inline-block;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  content: "";
}

.custom-carousel .carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  width: 100%;
  transition: height 0.15s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-carousel .carousel .slider-wrapper.axis-horizontal .slider {
  -ms-box-orient: horizontal;
  display: -moz-flex;
  display: flex;
  align-items: center;
}

.custom-carousel .carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center;
  height: 100%;
}

.carousel .thumbs-wrapper {
  margin-bottom: 50px !important;
}

.custom-carousel .carousel .slide img {
  max-height: 500px;
  width: 100%;
  border-radius: 10px;
  object-fit: contain;
}

.custom-carousel .carousel .thumb:active {
  border: 3px solid #4ea459;
}

.custom-carousel .carousel .thumb:hover {
  border: 3px solid #4ea459;
}

.custom-carousel .carousel .thumb {
  transition: border 0.15s ease-in;
  display: inline-flex;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  border: 3px solid #505058;
  padding: 2px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 60px;
  border-radius: 6px;
  border: 3px solid #d5d3d3;
}

.custom-carousel .carousel .thumbs {
  transition: all 0.15s ease-in;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  position: relative;
  list-style: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-carousel .carousel .thumb.selected,
.carousel .thumb:hover {
  border: 3px solid #4ea459;
}

.custom-carousel .carousel .carousel-status {
  display: none;
}

.custom-carousel .carousel .thumbs-wrapper ul {
  margin-bottom: 0 !important;
  padding-left: 0 !important;
}

.Barcode_input {
  margin-right: 1rem;
  margin-bottom: 5rem;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
          appearance: none;
  border-radius: 0.375rem;
}

.barcode_svg {
  margin-bottom: 5rem;
}

.img-adjust {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 10px #00000014;
  border-radius: 10px;
  margin: 20px;
  min-height: 470px;
}

.set-time {
  position: relative;
}

.set-time .date-product {
  padding: 5px 6px;
  background: #fff;
  border-radius: 6px;
  color: #4ea459;
  color: var(--active-color);
  position: absolute;
  top: 30px;
  right: 20px;
  font-size: 13px;
  border: 1px solid #4ea459;
  border: 1px solid var(--active-color);
}

.set-time .date-product svg {
  width: 12px;
  height: 12px;
  fill: #4ea459;
  fill: var(--active-color);
  vertical-align: -1px;
  padding-right: 3px;
}

.set-time .folder-details {
  position: absolute;
  right: 20px;
  bottom: 30px;
}

.folder-details .folder-btn {
  border: 1px solid #4ea459;
  border: 1px solid var(--active-color);
  background: #fff;
}

.folder-details .folder-btn:hover {
  border: 1px solid #4ea459;
  border: 1px solid var(--active-color);
  background: #4ea459;
  background: var(--active-color);
}

.set-time .folder-details .foldericon svg {
  width: 14px;
  height: 14px;
  fill: #4ea459;
  fill: var(--active-color);
  vertical-align: -2px;
}

.set-time .folder-details:hover .foldericon svg {
  width: 14px;
  height: 14px;
  fill: #fff;
  vertical-align: -2px;
}

.folder-btn {
  text-align: left;
  border: 1px solid #4ea459;
  border: 1px solid var(--active-color);
  background: #fff;
  border-radius: 6px;
  padding: 0px 8px;
}

.product-detailsinfo {
  margin-top: 10px;
  font-weight: bold;
  font-size: 25px;
}

.product-delinfo {
  margin-top: 10px;
  font-size: 15px;
  font-weight: bold;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
}

.product-costinfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
}

.product-costinfo .totalcost {
  color: #7e7d7d;
  color: var(--secondary-text-color);
  display: flex;
  align-items: center;
}

.product-costinfo .totalcost span {
  color: #4ea459;
  color: var(--active-color);
  font-size: 18px;
  padding-left: 10px;
}

.dynamic-data .main_bg {
  background: #fff;
}

.product-title {
  font-size: 16px;
  color: #505058;
  font-weight: 500;
}

.tooltipText {
  display: none;
}

/* Admin Dashboard */
.react-confirm-alert-body {
  text-align: center !important;
}

.react-confirm-alert-button-group {
  justify-content: center !important;
}

.admin-profile .dropdown .dropdown-menu[data-bs-popper] {
  left: -140%;
}

/* Crousel */
.custom-carousel
  .carousel
  .thumbs-wrapper
  .control-prev.control-arrow:hover::before {
  border-left: 8px solid #4ea459 !important;
}

.custom-carousel .carousel .thumbs-wrapper .control-next.control-arrow:before {
  border-left: 8px solid #4ea459;
}

/* profile.js */
.custom-select .css-t3ipsp-control:hover {
  border-color: transparent !important;
  box-shadow: none !important;
}

.custom-select .form-control .css-1xc3v61-indicatorContainer {
  margin-right: 0px;
}

.product-info .popularity strong {
  font-size: 25px;
}

.webCam {
  height: calc(100vh - 121px);
}

.webCam video {
  object-fit: cover;
}

.card-footer {
  margin-bottom: 7rem;
}

/* For edit box */
.custom-edit-input {
  position: relative !important;
  overflow-x: hidden;
  padding: 0;
  height: 130px;
  padding-left: 20px;
}

.custom-svg {
  position: absolute;
  left: 25px;
  top: 18px;
}

.custom-svg svg {
  width: 25px;
}

.swipeable-list-item__leading-actions {
  background: skyblue;
}

.swipeable-list-item__trailing-actions {
  background: red;
}

.swipeable-list-item__content {
  display: inline !important;
}

/* 03-10-23 new css edit btn 
 */
.custmEdit .swipeable-list-item__leading-actions {
  height: 83px;
  top: 11px;
  border-radius: 20px 0 0 20px;
}

.custmEdit .swipeable-list-item__trailing-actions {
  height: 83px;
  top: 11px;
  border-radius: 0px 20px 20px 0px;
  right: 26px;
}

.custmTransform {
  -webkit-transform: translateX(24px);
          transform: translateX(24px);
}

.swipeBtn span.input_search_icon {
  position: relative;
  top: unset;
  left: unset;
  -webkit-transform: unset;
          transform: unset;
}

.swipeable-list-item__trailing-actions .custom-svg {
  position: absolute;
  left: unset;
  top: 15px;
  right: 20px;
}

.swipeBtn .accordion-body {
  padding: 0 12px;
}

@media (max-width: 1099px) {
  .custmEdit .swipeable-list-item__trailing-actions {
    right: 0;
  }
}

@media (max-width: 767px) {
  .custmEdit .swipeable-list-item__leading-actions {
    height: 64px;
    top: 10px;
    border-radius: 20px 0 0 20px;
  }

  .custmEdit .swipeable-list-item__trailing-actions {
    height: 64px;
    top: 10px;
    border-radius: 0px 20px 20px 0px;
    right: 0;
  }

  .swipeable-list-item__leading-actions .custom-svg {
    top: 7px;
  }

  .swipeable-list-item__trailing-actions .custom-svg {
    top: 7px;
  }

  .swipeable-list-item__content-right .custom-svg {
    top: 7px;
  }

  .custmTransform {
    padding-left: 20px;
  }
}

.swipeable-list-item__content-right {
  z-index: 0 !important;
  background: skyblue;
  border-radius: 20px;
}

.swipeable-list-item__content-left {
  z-index: 0 !important;
  border-radius: 20px;
  background: red;
}

.right-svg {
  position: absolute;
  right: 25px;
  top: 25px;
}

.table {
  text-align: center;
}

.accept-condition {
  position: absolute;
  right: 4rem;
}

span.verohero_icon svg {
  width: 35px;
  height: 20px;
  fill: #f70808;
}

.d-flex {
  display: flex;
}

.terms-Conditionbtn {
  display: flex;
  justify-content: flex-end;
  grid-gap: 4px;
  gap: 4px;
}

.checkout_main_icon {
  padding-bottom: 15px;
}

/* .reseller-or center{
  padding-block:8px;
} */

span.check_terms {
  display: flex;
  align-items: baseline;
  grid-gap: 4px;
  gap: 4px;
  /* margin-bottom: 4px; */
}

@media screen and (max-width: 576px) {
  .terms-Condition {
    font-size: 16px;
  }
}

/*26-12-2023 Css Here*/
.cstmHomeHeading {
  font-size: 24px !important;
  font-weight: 800 !important;
}

.cstmNavPills li .addBTN {
  padding: 15px 3px;
  font-size: 16px;
  font-weight: 700;
  color: #4ea359;
}

.dataListingBox ul {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 0;
}

.dataListingBox ul li {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  width: 15%;
}

.dataListingBox ul li span {
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
  position: relative;
}

.dataListingBox ul li span:nth-child(2)::after {
  content: "";
  width: 90%;
  height: 3px;
  background-color: #000;
  display: flex;
  position: absolute;
  top: 50%;
  right: 100%;
}

.dataListingBox ul li:first-child span:nth-child(2)::after,
.dataListingBox ul li:last-child span:nth-child(2)::after {
  content: "";
  display: none;
}

.textBlue {
  color: #004aac;
}
.textGreen {
  color: #4fa358;
}
.textRed {
  color: #ff3131;
}
.swipeable-list > div > div {
  width: 100% !important;
}

/* Responsive css*/

@media (min-width: 1100px) and (max-width:1920px) {
    .main_content_wrapper div.row {
        width: 100%;
    }

}

@media (min-width: 992px) and (max-width:1500px) {
    .product-info {
        grid-gap: 8px 25px;
        gap: 8px 25px;
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
    }

    .signUp_Box {
        padding-right: 1rem;
        padding-left: 1rem;
    }

}

@media (min-width: 821px) and (max-width:991px) {
    .custom-nav {
        grid-gap: 1rem;
        gap: 1rem;
    }

    .nav-wrapper-header a {
        margin-right: 20px;
    }
    .home_navigation a.navbar-brand img {
        max-width: 145px;
    }
}

@media only screen and (max-height: 1400px) {


    .input-wrapper>.search_box {
        display: flex;
    }

    .input-wrapper>.search_box>span {
        -webkit-transform: none;
                transform: none;

    }

    .search_box>.fldname {
        margin: 0 !important;
    }

    .swipe-to-delete .js-delete {
        position: absolute !important;
        top: 0px !important;
        right: 0rem !important;
        bottom: 0 !important;
        left: 1.5rem !important;
        z-index: 1 !important;
        margin: 12px 0;
        border-radius: 10px;
        width: 94%;
        height: 70px;
    }
    .custom-edit-input .custom-edit-box{
        position: absolute !important;
        top: 0px !important;
        right: 0rem !important;
        bottom: 0 !important;
        left: 1.5rem !important;
        z-index: 1 !important;
        margin: 12px 0;
        border-radius: 10px;
        width: 94%;
        background: #87C351;
        height: 70px;
    }

}

@media(max-width: 1280px) {
    main.main_content_wrapper {
        padding-inline: 10px;
    }

    .signUp_Box {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

@media(max-width: 1024px) {
    .bg-img {
        height: calc(100vh - 30px);
    }

    .bg-img-search {
        height: calc(100vh - 2px);
    }

    .signUpForm_box {
        padding: 0;
    }

    .headerSec .navbar-brand img {
        max-width: 130px;
    }

    .profileMain_Box {
        padding-block: 70px;
    }

    .createProfile_Box1 {
        width: 100%;
    }

    .createProfile_Sec {
        background-color: #fff;
    }

    .createProfile_Box1.createProfile_Box2 {
        display: none;
    }

    .custom-card {
        margin-block: 10px;
    }

    h1.home_heading {
        font-size: 30px;
    }

    .signUp_Box h1 {
        font-size: 40px;
    }

    .pagination li.active,
    .pagination li:first-of-type,
    .pagination li:last-of-type,
    .pagination li:nth-of-type(1),
    .pagination li:nth-last-of-type(1) {
        position: initial;
        top: initial;
        left: initial;
    }

    .pagination li:nth-of-type(2) a {
        border-left-width: 0;
    }

    .pagination-wrapper .pagination .page-item .page-link {
        padding: 5px 6px !important;
        min-width: 35px !important;
    }
    .cards .col-md {
        flex: auto !important;
    }
    .card-border-radious {
        margin-bottom: 10px;
    }
    span.eye_iconA{
        right: 6% !important;
    }
    .home_navigation a.navbar-brand img {
        max-width: 118px;
    }
    .custom-nav{
        grid-gap: 1rem;
        gap: 1rem;
    }
    .nav-wrapper-header a {
        margin-right: 30px;
    }
}

@media screen and (max-width:991px) {
    .registrationsection {
        max-height: none;
        overflow: visible;
    }
}

@media(max-width: 820px) {
    .pagination li {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .pagination li.active,
    .pagination li:first-of-type,
    .pagination li:nth-of-type(2) {
        position: initial;
        top: initial;
        left: initial;
    }

    .pagination li.active,
    .pagination li:first-of-type,
    .pagination li:nth-of-type(3),
    .pagination li:nth-of-type(4),
    .pagination li:nth-of-type(5) {
        position: initial;
        top: initial;
        left: initial;
    }

    .pagination li:nth-of-type(1) a {
        border-left-width: 0;
    }

    .pagination-wrapper {
        margin: 0% auto;
    }
    .cards .col-md {
        flex: auto !important;
    }
    .card-border-radious {
        margin-bottom: 10px;
    }
    span.eye_iconA{
        right: 6% !important;
    }
    .home_navigation a.navbar-brand img {
        max-width: 118px;
    }
    .custom-nav{
        grid-gap: 1rem;
        gap: 1rem;
    }
    .nav-wrapper-header a {
        margin-right: 30px;
    }
}

@media (min-width: 768px) {
    [class*="col-"] {
        margin-right: 0 !important;
    }

}

@media(max-width: 768px) {
    .nav-wrapper-header a {
        margin-right: 20px !important;
    }

    .pagination li {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .pagination li.active,
    .pagination li:first-of-type,
    .pagination li:nth-of-type(2) {
        position: initial;
        top: initial;
        left: initial;
    }

    .pagination li.active,
    .pagination li:first-of-type,
    .pagination li:nth-of-type(3),
    .pagination li:nth-of-type(4),
    .pagination li:nth-of-type(5) {
        position: initial;
        top: initial;
        left: initial;
    }

    .pagination li:nth-of-type(1) a {
        border-left-width: 0;
    }

    .pagination-wrapper {
        margin: 0% auto;
    }

    .swipe-to-delete {
        padding-left: 5px !important;
    }
    /* For Dashboard */
    .cards .col-md{
      flex:auto !important;
    }
    .sidebar-sec {
        width: 260px;
    }
    .dashboard-body{
        margin-left: 270px;
    }
    .card-border-radious {
        margin-bottom: 10px;
    }
    span.eye_iconA{
        right: 9% !important;
    }
    .home_navigation a.navbar-brand img {
        max-width: 120px;
    }
}

@media(max-width: 767px) {
    .img-adjust {
        min-height: 350px;
    }
    .product-heading table {
        width: 100% !important;
    }

    .product-heading #eBay-bby-content .lft-flt {
        width: 100% !important;
    }

    .product-heading #eBay-bby-content {
        width: 100% !important;
        min-width: 100% !important;
    }

    .swipe-to-delete {
        padding-left: 5px !important;
        height: 110px !important;
    }

    .swipe-to-delete .js-delete {
        border-radius: 10px !important;
        width: 88% !important;
        height: 60px !important;
    }

    .fsearch_bar-wrapper {
        padding: 20px 10px !important;
    }

    .input-folder {
        margin-bottom: 0 !important;
    }

    .createProfile_FormBox {
        margin-top: 0;
    }

    .custom-card .product-img-wrapper {
        height: 110px;
    }

    .headerSec {
        margin: 6px;
    }

    .fpage_wrapper.comp_page-css {
        max-height: calc(100vh - 255px);
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 5px 8px;
    }

    .option-wrapper.my-3 .btn-group.filter-wrapper {
        justify-content: flex-end;
    }

    .home_navigation button.custom_toggle_btn {
        padding: 5px 10px;
        box-shadow: 0px 1px 6px #8b818166;
    }

    .spinnerrow .option-wrapper.my-3 {
        flex-direction: row;
        justify-content: space-between;
    }

    .signUpForm_BTN button.btn {
        margin: 20px 0;
        padding: 12px;
        font-size: 18px;
    }

    .signUp_Box h1 {
        font-size: 30px;
        max-width: 320px;
        margin-inline: auto;
    }

    .signUp_Box>span {
        font-size: 20px;
        margin-top: 10px;
    }

    .signUpForm_box {
        padding: 0px 20px 20px 20px;
    }

    .createProfile_Sec .signUp_Box h1 {
        text-align: center;
    }

    span.reseller-info {
        padding: 0 10px;
    }

    .fpage_wrapper {
        max-height: calc(100vh - 154px);
        padding: 10px 0 !important;
    }

    .createProfile_Sec .profileMain_Box {
        padding-block: 20px;
        padding-inline: 20px;
    }

    .home_navigation .custom-nav .nav-wrapper-header {
        position: fixed;
        z-index: 999;
        bottom: 0;
        grid-gap: 5px;
        gap: 5px;
        justify-content: space-between;
        left: 0;
        right: 0;
        padding: 15px;
        background-color: #fff;
        box-shadow: 0 6px 35px -12px rgb(0 0 0 / 42%);
    }

    .sidebar {
        display: none;
    }

    main.main_content_wrapper {
        width: auto;
        margin-left: auto;
        padding-inline: 4px;
    }

    .page_wrapper {
        padding: 15px 0 100px 0;
    }

    h1.home_heading {
        font-size: 30px;
        font-weight: 900;
    }

    .search_bar-wrapper {
        padding: 25px 10px;
        box-shadow: none;
        border-radius: 20px;
        display: flex;
        justify-content: space-between;
        max-width: 860px;
        margin: 0px auto;
        flex-wrap: wrap;
        background: transparent;
    }

    .input-wrapper input.form-control:focus {
        border: 1px solid #4ca45a;
        box-shadow: 0px 15px 33px #AFB5B091;
    }

    .input-wrapper input.form-control {
        padding: 12px 15px 12px 40px;
        border: 1px solid #4ca45a;
        border-radius: 10px;
        box-shadow: 0px 15px 33px #AFB5B091;
    }

    .input-folder input.form-control:focus {
        border: none !important;
        box-shadow: none !important;
    }

    .input-folder input.form-control {
        padding: 12px 15px 12px 40px;
        border: none !important;
        border-radius: 0 !important;
        box-shadow: none !important;
    }

    .fldname {
        font-size: 16px;
        padding-left: 10px;
    }

    span.input_search_icon svg {
        width: 30px;
        height: 16px;
    }

    span.input_search_icon {
        left: 10px;
    }

    .screnQrCustm {
        margin-bottom: 22px;
        width: 100%;
        box-shadow: 0px 15px 33px #AFB5B091;
        background: #fff;
        margin-right: 0px !important;

    }

    .input-wrapper {
        position: relative;
        width: 100%;
        margin-bottom: 22px;
    }

    .input-wrapper input.form-control::-webkit-input-placeholder {
        font-size: 15px;
    }

    .input-wrapper input.form-control::placeholder {
        font-size: 15px;
    }

    .screnQrCustm input.form-control::-webkit-input-placeholder {
        font-size: 15px;
    }

    .screnQrCustm input.form-control::placeholder {
        font-size: 15px;
    }

    #appleicon {
        display: block;
        position: relative;
    }

    .tooltipText {
        display: block !important;
        position: absolute;
        top: 35px;
        font-size: 12px;
        left: -17px;
        color: #E91E63;
        transition: all .5s;
        opacity: 0;
    }

    .screnQrCustm .appleicon:hover .tooltipText {
        opacity: 1;
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

    .btn-grp_wrapper button.btn.custom_btn {
        width: 100%;
    }

    .btn-grp_wrapper button.btn.custom_btn:first-child {
        margin-top: 30px;
    }

    .btn-grp_wrapper button.btn.custom_btn:last-child {
        margin-top: 10px;
    }

    .btn.action_btn {
        padding-block: 12px;
        margin: 0 auto;
        max-width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .mobile-header-heading {
        display: block;
    }

    .home_heading {
        display: none;
    }

    .search_bar-wrapper .btn-grp_wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    .mobile-nav.text-start {
        display: block;
    }

    .signUp_sec {
        margin-top: 40px;
    }

    .customNav_bar {
        margin-top: 15px;
    }

    .signUp_Box p {
        margin: 20px 0;
    }

    .spinnerrow .d-flex {
        padding-left: 0 !important;
        flex-wrap: wrap !important;
    }

    .spinnerrow .d-sort {
        display: flex;
        grid-gap: 0;
        gap: 0;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .card-width {
        padding-inline: 4px !important;
        margin-bottom: 0;
    }

    .card_content {
        padding: 6px 8px;
    }

    h3.product-heading.mt-2 small {
        font-size: 16px;
    }

    .product-info {
        margin-top: 10px;
        display: flex;
        grid-gap: 5px;
        gap: 5px;
        font-size: 11px;
        font-weight: bold;
        grid-template-columns: auto auto auto;
        align-items: center;
    }

    .product-info span.popularity small {
        font-size: 12px;
    }

    span.shipping-price svg,
    span.popularity svg {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        vertical-align: text-bottom;
    }

    span.shipping-price {
        font-size: 12px;
        /* margin-left: auto; */
    }

    span.product-cost {
        font-size: 12px;
    }

    span.product-cost svg {
        width: 12px;
    }

    .date-str-info .folder-info {
        position: absolute;
        top: 70px !important;
    }

    .pagination-wrapper {
        margin: 5% auto;
    }

    .custom-carousel .carousel .control-arrow {
        top: 5px !important;
    }

    .custom-carousel .carousel .thumb {
        height: 40px !important;
    }

    .home_navigation a.nav_icon_link {
        text-align: center;
        font-size: 15px;
    }

    .home_navigation a.nav_icon_link span.nav_icon {
        display: block;
        margin: 0;
        margin-bottom: 10px;
    }

    .nav-wrapper-header a.nav_icon_link:last-child {
        display: inline-block;
    }

    .main_content_wrapper div.row {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1 * var(--bs-gutter-y));
        margin-right: calc(-.5 * var(--bs-gutter-x));
        margin-left: calc(-.5 * var(--bs-gutter-x));
    }

    .set-time .date-product {
        top: 20px !important;
        right: 15px !important;
    }

    .set-time .folder-details {
        right: 15px !important;
        bottom: 50px !important;
    }

    .product-info .popularity strong {
        font-size: 20px;
    }
    /* For admin dashboard */
    .sidebar-sec {
        width: 150px;
    }        
    .sidebar-sec .sidebar-menu .dashboard-list li a i {
        margin-right: 12px;
        font-size: 25px;
    }
    .link-name{
        font-size: 15px;
    }
    .dashboard-body {
        width: calc(100% - 140px);
        margin-left: 140px;
    }
    .card-border-radious{
        margin-bottom: 10px;
        min-height: 100px;
    }
    .input-place::-webkit-input-placeholder{
        font-size: 11px;
    }
    .input-place::placeholder{
        font-size: 11px;
    }
    .main-content h2{
        font-size: 20px;
    }
    span.eye_iconA {
        right: 9% !important;
    }
}


@media(max-width: 567px) {

    .option-wrapper .btn {
        padding: 6px 9px !important;
    }

    .dropdown .btn-toggle {
        padding: 6px 9px !important;
    }

    .home_navigation a.nav_icon_link {
        margin: 0;
        font-size: 15px;
    }

    .tab-wrapper .nav-item .nav-link {
        padding: 15px 7px;
    }

    .product-info {
        grid-gap: 10px;
        grid-gap: 4px 15px;
        gap: 4px 15px;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    h3.product-heading.mt-2 {
        min-height: 10px;
    }

    .pagination-wrapper .pagination .page-item .page-link {
        padding: 5px 6px !important;
        min-width: 35px !important;
    }

    .pagination-wrapper .pagination {
        padding: 5px !important;
    }
}



/* For landscape */
@media screen and (orientation:landscape) and (min-device-width: 360px) and (max-device-width: 900px) {
    .bg-img {
        height: 100%;
    }

    .bg-img-search {
        height: 100%;
    }

    .nav-wrapper-header a {
        margin-right: 20px !important;
    }

    .pagination li {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .pagination li.active,
    .pagination li:first-of-type,
    .pagination li:nth-of-type(2) {
        position: initial;
        top: initial;
        left: initial;
    }

    .pagination li:nth-of-type(2) a {
        border-left-width: 0;
    }

    .pagination li.active,
    .pagination li:first-of-type,
    .pagination li:nth-of-type(3),
    .pagination li:nth-of-type(4),
    .pagination li:nth-of-type(5) {
        position: initial;
        top: initial;
        left: initial;
    }
}


@media screen and (max-width:767px) {
    .customInput_Box input {
        padding: 12px 20px;
    }

    span.eye_icon {
        right: 6%;
        top: 13px;
    }

    span.eye_icon svg {
        width: 18px;
        height: 18px;
    }

    .checkout_Boxcstm1 .checkout_Form_box {
        padding: 30px 30px;
    }

    .main_content_wrapper2 .fldsearch {
        padding: 15px 20px !important
    }

    .main_content_wrapper2 .addfld1 {
        padding: 28px 20px !important;
    }
}

@media screen and (max-width:767px) {
    .main_content_wrapper2 .fldsearch {
        padding: 8px 15px !important
    }

    .main_content_wrapper2 .addfld1 {
        padding: 21px 15px !important;
    }
}

@media only screen and (max-width: 767px) {
    li.nav-item {
        font-size: 14px;
    }

    .option-wrapper.my-3 {
        width: 100%;
        flex-direction: column;
        align-items: baseline;
    }
}

@media(max-width:281px) {
    .product-info span.popularity small {
        font-size: 0.6rem;
    }

    .product-info span.shipping-price svg {
        width: 16px;
    }

    .product-info span.shipping-price {
        font-size: 0.6rem;
    }

    .product-info span.product-cost {
        font-size: 0.7rem;
    }

    .product-info span.product-cost svg {
        width: 16px;
    }

    ul.pagination li.page-item .page-link {
        font-size: 0.7rem;
    }
}

/* For Pagination */
@media only screen and (max-width: 1025px) {
    .pagination li:nth-of-type(2) a {
        border-left-width: 1px;
    }
}

@media only screen and (max-width: 641px) {
    .pagination li {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .pagination li.active,
    .pagination li:first-of-type,
    .pagination li:nth-of-type(2) {
        position: initial;
        top: initial;
        left: initial;
    }

    .pagination li:nth-of-type(2) a {
        border-left-width: 0;
    }
}

@media only screen and (max-width: 481px) {

    h1 {
        font-size: 1.35em !important;
    }

    .pagination li:nth-of-type(2) a {
        border-left-width: 1px;
    }
}

@media only screen and (max-width: 576px) {

    .Coupon_Input {
        max-width:165px
    }
    .Coupon_btn1{
        width:auto;
    }
    ul.pagination {
        flex-wrap: wrap;
        justify-content: center;
    }

    a.custom-card-wrapper span.accordion-header {
        display: inline-block;
        width: 85%;
    }

    a.custom-card-wrapper span.input_search_icon {
        display: flex;
        width: 15%;
        height: 50px;
        padding: 5px;
        justify-content: center;
        align-items: center;
    }

}


@media screen and (max-width:380px){
  
    .input-wrapper {
        margin-bottom: 0px;
    }

 

 
    .checkout_Boxcstm1 .checkout_Form_box {
        padding: 16px;
    }
  
}

@media(max-width: 360px) {
    .tab-wrapper .nav-item .nav-link {
        padding: 12px 10px;
    }
    .signUp_sec {
        margin-top: 25px;
    }

    .headerSec {
        margin: 4px 0;
    }

    h3.product-heading.mt-2 {
        min-height: 8px;
    }

    span.product-cost {
        margin-top: -10px;
    }

    .product-info {
        grid-gap: 0px;
        grid-gap: 14px;
        gap: 14px;
        flex-wrap: wrap;
    }

    .pagination li.active,
    .pagination li:first-of-type,
    .pagination li:nth-of-type(3),
    .pagination li:nth-of-type(4) {
        position: initial;
        top: initial;
        left: initial;
    }

    .pagination li {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .pagination-wrapper .pagination {
        padding: 3px !important;
    }
}

@media screen and (max-width:576px) {
    .dataListingBox ul li {
        width: auto;
    }

    .dataListingBox ul li span {
        font-size: 14px;
    }

    .mobile-nav.text-start a {
        font-size: 15px;
    }
}

@media screen and (min-width:330px) and (max-width:450px) {
    .mobile-nav.text-start a{
        width: 325px;
        display: block;
        position: relative;
        padding-left: 25px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: break-word;
    }

    .mobile-nav.text-start a svg {
        position: absolute;
        top: 2px;
        left: 0;
    }
    .flex-res{
        display: flex !important;
    }
    .mobile-nav.text-start .pro-title{
        width:250px;
    }
}

@media screen and (min-width:280px) and (max-width:485px) {
    .dataListingBox ul li span:nth-child(2)::after {
        width: 30%;
        /* width: 70%; */
    }
}
/*5/1/2024*/
@media screen and (max-width: 992px) {
    #title_product {
      visibility: hidden;
      clear: both;
      float: left;
      margin: 10px auto 5px 20px;
      width: 28%;
      display: none;
    }
    #prodct-title{
        font-size: 22px;
    }
  }
